import {createApp} from 'vue'
import App from './ui/App.vue'
import router from "@/router";
import naive from 'naive-ui'
import '@/assets/css/global.scss'
import '@/assets/css/normal.scss'
import piniaInstance from '@/data/store'
import {VueRouterTask} from "vimi-flow";
VueRouterTask.router=router
const app = createApp(App)
app.use(router)
app.use(naive)
app.use(piniaInstance)
app.mount('#app')
