import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/ui/components/layout/EmptyLayout'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/ui/pages/home/HomePage')
            }
        ]
    },
    {
        path: '/example',
        name: 'example',
        redirect: '/example/index',
        component: () => import('@/ui/components/layout/ExampleLayout'),
        children: [
            {
                path: 'index',
                name: 'index',
                component: () => import('@/ui/pages/exampleLoader/exampleLoader')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/ui/pages/login/LoginPage')
    }

]

const router = createRouter({

    history: createWebHashHistory(),

    routes

})

export default router
